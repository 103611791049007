<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <form @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Dados Básicos</h2>
            <div class="flex flex-col">
              <label class="mb-2" for="document"
                >Escolaridade
                <p class="font-light ">
                  *Para alterar a escolaridade atualize a sua formação acadêmica
                  na página
                  <router-link
                    :to="{ name: 'my-complete-curriculum' }"
                    class="text-red-600"
                  >
                    Meu Currículo.
                  </router-link>
                </p></label
              >
              <input
                id="document"
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none cursor-not-allowed"
                :mask="'###.###.###-##'"
                :value="educationLevels.selected"
                readonly
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="document">CPF</label>
              <the-mask
                id="document"
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none cursor-not-allowed"
                :mask="'###.###.###-##'"
                :value="document"
                readonly
              ></the-mask>
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="telephone">Telefone</label>
              <input
                id="telephone"
                type="text"
                v-model="telephone"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="Telefone"
                v-mask="'(##) ####-####'"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="cellphone">Celular</label>
              <input
                id="cellphone"
                type="text"
                v-model="cellphone"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="cellphone"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="birth-date">Data de Nascimento</label>
              <input
                id="birth-date"
                type="text"
                v-model="birthDate"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="Data de Nascimento"
                v-mask="'##/##/####'"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="gender">Sexo</label>
              <multiselect
                id="gender"
                v-model="genders.selected"
                deselect-label="Remover"
                track-by="label"
                label="label"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione"
                :options="genders.options"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
                <span slot="noOptions">Nenhum registro encontrado.</span>
              </multiselect>
            </div>

            <div class="flex flex-col mt-4">
              <span>
                <input
                  id="notifications"
                  type="checkbox"
                  v-model="notifications"
                />
                Receber notificações?
              </span>
            </div>

            <div class="flex justify-between">
              <button
                class="bg-gray-400 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
                @click.prevent="goBack"
              >
                Voltar
              </button>

              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import { mask, TheMask } from 'vue-the-mask';
import { required } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'ProfileAddress',

  title() {
    return `${process.env.VUE_APP_NAME} | Meu Perfil`;
  },

  components: {
    DashboardLayout,
    TheMask,
    LoaderAnimation
  },

  directives: {
    mask
  },

  data() {
    return {
      isLoading: false,
      notifications: null,
      educationLevels: {
        options: [],
        selected: null
      },
      document: '',
      telephone: '',
      cellphone: '',
      birthDate: '',
      genders: {
        options: [
          { label: 'Masculino', value: 'M' },
          { label: 'Feminino', value: 'F' }
        ],
        selected: null
      }
    };
  },

  validations: {
    educationLevels: {
      selected: {
        required
      }
    }
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    async fetchParticipant() {
      this.isLoading = false;
      const participant = await axios.get('/api/perfil');

      if (participant.data.data) {
        this.educationLevels.selected =
          participant.data.data.educationLevel.name;
        this.document = participant.data.data.document;
        this.telephone = participant.data.data.telephone;
        this.cellphone = participant.data.data.cellphone;
        this.birthDate = participant.data.data.birthDate;
        this.genders.selected = participant.data.data.gender;
      }
      const notifications = await axios.get(
        `/api/subscription/${this.user.id}`
      );
      if (notifications.data.data != null) {
        this.notifications = notifications.data.data.accept;
        this.isLoading = false;
      } else {
        this.notifications = true;
        this.isLoading = false;
      }
    },

    async updateProfile(id) {
      const participant = await axios.get('/api/perfil');

      await axios
        .patch('/api/perfil', {
          education_level_id: id,
          telephone: participant.data.data.telephone,
          cellphone: participant.data.data.cellphone,
          birth_date: participant.data.data.birthDate,
          gender: participant.data.data.gender.value
        })
        .then(({ data }) => {
          return data.data.message;
        });
    },

    // Verifica a escolaridade e o perfil, sendo diferentes sincroniza o perfil com a ultima escolaridade
    async syncInformation() {
      const participant = await axios.get('/api/perfil');

      participant.data.data.academicBackgrounds.forEach(academicBackgrounds => {
        if (
          academicBackgrounds.education_level.level >
          participant.data.data.educationLevel.level
        )
          this.updateProfile(academicBackgrounds.education_level.id);
      });
    },

    submit() {
      if (this.user.role.name === 'Instructor') {
        this.$confirm({
          title: 'Usuário de demonstração',
          message:
            'Usuário de demonstração não tem permissão para alterar dados.',
          button: {
            yes: 'OK'
          }
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$v.cities.selected.$touch();
      } else {
        axios
          .patch('/api/perfil', {
            education_level_id: this.educationLevels.selected.id,
            telephone: this.telephone,
            cellphone: this.cellphone,
            birth_date: this.birthDate,
            gender: this.genders.selected.value
          })
          .then(({ data }) => {
            if (this.notifications === true) {
              axios.post(`/api/subscription/subscribe/${this.user.id}`);
            } else {
              axios.post(`/api/subscription/unsubscribe/${this.user.id}`);
            }

            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'my-complete-curriculum'
            });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    goBack() {
      if (
        this.user.role.name === 'participant' ||
        this.user.role.name === 'Instructor'
      ) {
        this.$router.push({
          name: 'my-complete-curriculum'
        });
      } else {
        this.$router.push({
          name: 'search'
        });
      }
    },

    async fetchEducationLevels() {
      await axios.get('/api/escolaridades').then(({ data }) => {
        data.data.forEach(educationLevel =>
          this.educationLevels.options.push({
            id: educationLevel.id,
            name: educationLevel.name
          })
        );
      });
    }
  },

  created() {
    if (this.user.role.name === 'Instructor') {
      return;
    }
    this.syncInformation();
    this.fetchEducationLevels();
    this.fetchParticipant();
  }
};
</script>

<style scoped></style>
